const plans = [
  {
    id: 13890,
    ids: ['13890'],
    name: 'PATHADVICE Free',
    title: 'pricing.basic',
    price: 'pricing.free',
    cost: 'pricing.callsPerMonth',
    category: 'free',
    isBestSeller: false,
    isCurrentPlan: false,
    isEnterprisePlan: false,
    level: 1,
    previousOptions: [],
    newOptions: [
      {
        title: '',
        description: 'features.websiteLiveContactWidgetIncludingChatPhoneAndVideo'
      },
      {
        title: '',
        description: 'features.fullStackCalendar'
      }
    ],
    enabled: false
  },
  {
    id: 13892,
    ids: ['13892'],
    name: 'PATHADVICE Premium',
    title: 'pricing.premium',
    price: 49,
    recurring_price: {
      EUR: '49.00'
    },
    cost: 'pricing.perUserPerMonth',
    category: 'premium',
    isBestSeller: true,
    isCurrentPlan: false,
    isEnterprisePlan: false,
    level: 10,
    previousOptions: ['pricing.allTheBenefitsOfBasicPlus'],
    newOptions: [
      {
        title: '',
        description: 'features.proSolutionsBundle'
      },
      {
        title: '',
        description: 'features.unlimitedVisitorIdentification'
      },
      {
        title: '',
        description: 'features.liveContactViaQRCode'
      },
      {
        title: '',
        description: 'features.customBranding'
      },
      {
        title: '',
        description: 'features.fullLiveSupport'
      }
    ],
    billing_type: 'month',
    enabled: false
  },
  {
    id: 'M_MONTHLY_FREE',
    ids: ['M_MONTHLY_FREE'],
    name: 'Free',
    description: 'FREE MONTHLY PLAN',
    subtitle: "Let's Connect Free",

    recurring_price: {
      EUR: '0.00',
      AUD: '0.00',
      USD: '0.00',
      GBP: '0.00'
    },
    totalUsers: 5,
    title: 'features.free',
    price: 0,
    cost: 'pricing.perUserPerMonth',
    users: 'pricing.basicUsersIncluded',
    category: 'free',
    isBestSeller: false,
    isCurrentPlan: false,
    isEnterprisePlan: false,
    level: 13,
    previousOptions: [],
    newOptions: [
      {
        title: '',
        description: 'features.websiteLiveContactWidgetIncludingChatPhoneAndVideo'
      }
    ],
    billing_type: 'month',
    enabled: true,
    isFree: true
  },
  {
    id: 'M_BASIC_MONTHLY',
    ids: ['M_BASIC_MONTHLY'],
    name: 'Basic',
    description: 'BASIC MONTHLY PLAN',
    subtitle: "Let's Connect Basic",
    recurring_price: {
      EUR: '49.00',
      AUD: '73.00',
      USD: '54.00',
      GBP: '41.00'
    },
    totalUsers: 5,
    title: 'pricing.basic',
    price: 49,
    cost: 'pricing.perUserPerMonth',
    users: 'pricing.basicUsersIncluded',
    category: 'basic',
    isBestSeller: false,
    isCurrentPlan: false,
    isEnterprisePlan: false,
    level: 13,
    previousOptions: [],
    newOptions: [
      {
        title: '',
        boldify: true,
        description: 'features.freeSolutionsBundle'
      },
      {
        title: '',
        description: 'features.contactFormToCollectLeads'
      },
      {
        title: '',
        description: 'features.screensharing'
      }
    ],
    billing_type: 'month',
    enabled: true,
    isFree: false
  },
  {
    id: 13891,
    ids: ['13891'],
    name: 'PATHADVICE Premium',
    title: 'pricing.premium',
    price: 540,
    recurring_price: {
      EUR: '540.00'
    },
    cost: 'pricing.perUserPerMonth',
    category: 'premium',
    isBestSeller: true,
    isCurrentPlan: false,
    isEnterprisePlan: false,
    level: 11,
    previousOptions: ['pricing.allTheBenefitsOfBasicPlus'],
    newOptions: [
      {
        title: '',
        description: 'features.proSolutionsBundle'
      },
      {
        title: '',
        description: 'features.unlimitedVisitorIdentification'
      },
      {
        title: '',
        description: 'features.liveContactViaQRCode'
      },
      {
        title: '',
        description: 'features.customBranding'
      },
      {
        title: '',
        description: 'features.fullLiveSupport'
      }
    ],
    billing_type: 'year',
    enabled: false
  },
  {
    id: 'M_ANNUAL_FREE',
    ids: ['M_ANNUAL_FREE'],
    name: 'Free',
    description: 'FREE ANNUAL PLAN',
    subtitle: "Let's Connect Free",
    title: 'features.free',
    price: 0,
    recurring_price: {
      EUR: '0.00',
      AUD: '0.00',
      USD: '0.00',
      GBP: '0.00'
    },
    totalUsers: 5,
    cost: 'pricing.perUserPerMonth',
    users: 'pricing.basicUsersIncluded',
    category: 'free',
    isBestSeller: false,
    isCurrentPlan: false,
    isEnterprisePlan: false,
    level: 14,
    previousOptions: [],
    newOptions: [
      {
        title: '',
        description: 'features.websiteLiveContactWidgetIncludingChatPhoneAndVideo'
      }
    ],
    billing_type: 'year',
    billing_interval: 'pricing.billedMonthly',
    enabled: true,
    isFree: true
  },
  {
    id: 'M_BASIC_ANNUAL',
    ids: ['M_BASIC_ANNUAL'],
    name: 'Basic',
    description: 'BASIC ANNUAL PLAN',
    subtitle: "Let's Connect Basic",
    title: 'pricing.basic',
    price: 540,
    recurring_price: {
      EUR: '45.00',
      AUD: '67.00',
      USD: '49.00',
      GBP: '38.00'
    },
    totalUsers: 5,
    cost: 'pricing.perUserPerMonth',
    users: 'pricing.basicUsersIncluded',
    category: 'basic',
    isBestSeller: false,
    isCurrentPlan: false,
    isEnterprisePlan: false,
    level: 14,
    previousOptions: [],
    newOptions: [
      {
        title: '',
        boldify: true,
        description: 'features.freeSolutionsBundle'
      },
      {
        title: '',
        description: 'features.contactFormToCollectLeads'
      },
      {
        title: '',
        description: 'features.screensharing'
      }
    ],
    billing_type: 'year',
    billing_interval: 'pricing.billedMonthly',
    enabled: true,
    isFree: false
  },
  {
    id: 13887,
    ids: ['13887', '14833'],
    name: 'PATHADVICE Professional',
    title: 'pricing.pro',
    price: 99,
    recurring_price: {
      EUR: '99.00'
    },
    cost: 'pricing.perUserPerMonth',
    category: 'professional',
    isBestSeller: false,
    isCurrentPlan: false,
    isEnterprisePlan: false,
    level: 20,
    previousOptions: ['pricing.allTheBenefitsOfBasic', 'pricing.allTheGoodnessOfPremiumIncluding'],
    newOptions: [
      {
        title: '',
        description: 'features.proSolutionsBundle'
      },
      {
        title: '',
        description: 'features.unlimitedVisitorIdentification'
      },
      {
        title: '',
        description: 'features.liveContactViaQRCode'
      },
      {
        title: '',
        description: 'features.customBranding'
      },
      {
        title: '',
        description: 'features.fullLiveSupport'
      }
    ],
    billing_type: 'month',
    enabled: false
  },
  {
    id: 'M_PROFESSIONAL_MONTHLY',
    ids: ['M_PROFESSIONAL_MONTHLY'],
    name: 'Professional',
    description: 'PROFESSIONAL MONTHLY PLAN',
    subtitle: "Let's Connect Pro",
    title: 'pricing.pro',
    price: 279,
    recurring_price: {
      EUR: '279.00',
      AUD: '415.00',
      USD: '307.00',
      GBP: '233.00'
    },
    totalUsers: 5,
    cost: 'pricing.perUserPerMonth',
    users: 'pricing.professionalUsersIncluded',
    category: 'new_professional',
    isBestSeller: true,
    isCurrentPlan: false,
    isEnterprisePlan: false,
    level: 22,
    previousOptions: ['pricing.allTheBenefitsOfBasicPlus'],
    newOptions: [
      {
        title: '',
        boldify: true,
        description: 'features.basicSolutionsBundle'
      },
      {
        title: '',
        description: 'features.appointmentBooking'
      },
      {
        title: '',
        description: 'features.unlimitedVisitorIdentification'
      },
      {
        title: '',
        description: 'features.liveContactViaQRCode'
      },
      {
        title: '',
        description: 'features.ABTesting'
      },
      {
        title: '',
        description: 'features.whitelabel'
      }
    ],
    billing_type: 'month',
    enabled: true
  },
  {
    id: 13886,
    ids: ['13886', '14832'],
    name: 'PATHADVICE Professional',
    title: 'pricing.pro',
    price: 1080,
    recurring_price: {
      EUR: '1080.00'
    },
    cost: 'pricing.perUserPerMonth',
    category: 'professional',
    isBestSeller: false,
    isCurrentPlan: false,
    isEnterprisePlan: false,
    level: 21,
    previousOptions: ['pricing.allTheBenefitsOfBasic', 'pricing.allTheGoodnessOfPremiumIncluding'],
    newOptions: [
      {
        title: '',
        description: 'features.proSolutionsBundle'
      },
      {
        title: '',
        description: 'features.unlimitedVisitorIdentification'
      },
      {
        title: '',
        description: 'features.liveContactViaQRCode'
      },
      {
        title: '',
        description: 'features.customBranding'
      },
      {
        title: '',
        description: 'features.fullLiveSupport'
      }
    ],
    billing_type: 'year',
    enabled: false
  },
  {
    id: 'M_PROFESSIONAL_ANNUAL',
    ids: ['M_PROFESSIONAL_ANNUAL'],
    name: 'Professional',
    description: 'PROFESSIONAL ANNUAL PLAN',
    title: 'pricing.pro',
    subtitle: "Let's Connect Pro",
    price: 2988,
    recurring_price: {
      EUR: '249.00',
      AUD: '368.00',
      USD: '275.00',
      GBP: '208.00'
    },
    lottery_price: {
      EUR: '334.80'
    },
    totalUsers: 5,
    cost: 'pricing.perUserPerMonth',
    users: 'pricing.professionalUsersIncluded',
    category: 'new_professional',
    isBestSeller: true,
    isCurrentPlan: false,
    isEnterprisePlan: false,
    level: 23,
    previousOptions: ['pricing.allTheBenefitsOfBasicPlus'],
    newOptions: [
      {
        title: '',
        boldify: true,
        description: 'features.basicSolutionsBundle'
      },
      {
        title: '',
        description: 'features.appointmentBooking'
      },
      {
        title: '',
        description: 'features.unlimitedVisitorIdentification'
      },
      {
        title: '',
        description: 'features.liveContactViaQRCode'
      },
      {
        title: '',
        description: 'features.ABTesting'
      },
      {
        title: '',
        description: 'features.whitelabel'
      }
    ],
    billing_type: 'year',
    billing_interval: 'pricing.billedMonthly',
    enabled: true
  },
  {
    id: 'M_ENTERPRISE_MONTHLY',
    ids: ['M_ENTERPRISE_MONTHLY'],
    name: 'Enterprise',
    description: 'ENTERPRISE MONTHLY PLAN',
    subtitle: "Let's Connect Enterprise",
    title: 'pricing.enterprise',
    isCustomPrice: true,
    quote: 'pricing.getACustomQuote',
    team: 'pricing.forEstablishedTeams',
    price: 1495,
    recurring_price: {
      EUR: '1495.00',
      AUD: '2208.00',
      USD: '1648.00',
      GBP: '1245.00'
    },
    totalUsers: 10,
    cost: 'pricing.perUserPerMonth',
    users: 'pricing.enterpriseUsersIncluded',
    category: 'new_enterprise',
    isBestSeller: false,
    isCurrentPlan: false,
    isEnterprisePlan: true,
    level: 50,
    previousOptions: ['pricing.allTheBenefitsOfBasic', 'pricing.allTheGoodnessOfPremiumIncluding'],
    newOptions: [
      {
        title: '',
        boldify: true,
        description: 'features.proSolutionsBundle'
      },
      {
        title: '',
        description: 'features.assignmentRules'
      },
      {
        title: '',
        description: 'features.advancedAnalytics'
      },
      {
        title: '',
        description: 'features.prioritySupport'
      },
      {
        title: '',
        description: 'features.personalSetup'
      }
    ],
    billing_type: 'month',
    enabled: true
  },
  {
    id: 'M_ENTERPRISE_ANNUAL',
    ids: ['M_ENTERPRISE_ANNUAL'],
    name: 'Enterprise',
    description: 'ENTERPRISE ANNUAL PLAN',
    subtitle: "Let's Connect Enterprise",
    title: 'pricing.enterprise',
    isCustomPrice: true,
    quote: 'pricing.getACustomQuote',
    team: 'pricing.forEstablishedTeams',
    price: 16140,
    recurring_price: {
      EUR: '1345.00',
      AUD: '1987.00',
      USD: '1485.00',
      GBP: '1119.00'
    },
    totalUsers: 10,
    cost: 'pricing.perUserPerMonth',
    users: 'pricing.enterpriseUsersIncluded',
    category: 'new_enterprise',
    isBestSeller: false,
    isCurrentPlan: false,
    isEnterprisePlan: true,
    level: 51,
    previousOptions: ['pricing.allTheBenefitsOfBasic', 'pricing.allTheGoodnessOfPremiumIncluding'],
    newOptions: [
      {
        title: '',
        boldify: true,
        description: 'features.proSolutionsBundle'
      },
      {
        title: '',
        description: 'features.assignmentRules'
      },
      {
        title: '',
        description: 'features.advancedAnalytics'
      },
      {
        title: '',
        description: 'features.prioritySupport'
      },
      {
        title: '',
        description: 'features.personalSetup'
      }
    ],
    billing_type: 'year',
    billing_interval: 'pricing.billedMonthly',
    enabled: true
  },
  {
    id: 0,
    ids: ['0'],
    name: 'PATHADVICE Enterprise',
    title: 'pricing.enterprise',
    price: 'pricing.enterprisePriceTitle',
    cost: 'pricing.moreInformation',
    category: 'enterprise',
    isBestSeller: false,
    isCurrentPlan: false,
    isEnterprisePlan: true,
    level: 30,
    previousOptions: ['pricing.allTheBenefitsOfPremium', 'pricing.allTheGoodnessOfProfessional', 'pricing.allTheGreatnessOfProfessionalPlus'],
    newOptions: [
      {
        title: '',
        description: 'features.enterpriseSolutionsBundle'
      },
      {
        title: '',
        description: 'features.advancedAnalytics'
      },
      {
        title: '',
        description: 'features.prioritySupport'
      },
      {
        title: '',
        description: 'features.personalSetup'
      }
    ],
    enabled: false
  }
]
exports.plans = plans
