const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export const paddle = {
  methods: {
    localizeProperties(subscriptonPlans) {
      subscriptonPlans.forEach((x) => {
        x.title = this.$i18n.t(x.title)
        x.price = this.$i18n.t(x.price)
        x.cost = this.$i18n.t(x.cost)
        x.previousOptions.forEach((part, index, arr) => {
          arr[index] = this.$i18n.t(part)
        })
        x.newOptions.forEach((no) => {
          no.title = this.$i18n.t(no.title)
          no.description = this.$i18n.t(no.description)
        })
      })
    },
    getBaseSubscriptionPlans() {
      const hostname = document.location.hostname
      let environment = 'stage'
      switch (hostname) {
        case 'app.pathadvice.ai':
          environment = 'prod'
          break

        case 'app.pathadvice.at':
          environment = 'prod'
          break

        case 'app.letsconnect.at':
          environment = 'prod'
          break
      }
      let subscriptionPlans = null

      switch (environment) {
        case 'stage':
          subscriptionPlans = JSON.parse(JSON.stringify(require('@/assets/plans/stage_plans.js').plans))
          this.localizeProperties(subscriptionPlans)
          break

        case 'prod':
          subscriptionPlans = JSON.parse(JSON.stringify(require('@/assets/plans/prod_plans.js').plans))
          this.localizeProperties(subscriptionPlans)
          break
      }

      return subscriptionPlans
    },
    getFreePlanId() {
      const hostname = document.location.hostname
      let environment = 'stage'
      switch (hostname) {
        case 'app.pathadvice.ai':
          environment = 'prod'
          break

        case 'app.pathadvice.at':
          environment = 'prod'
          break

        case 'app.letsconnect.at':
          environment = 'prod'
          break
      }
      let freePlanId = null

      switch (environment) {
        case 'prod':
          freePlanId = 634410
          break

        case 'stage':
          freePlanId = 13890
          break

        default:
          freePlanId = 13890
          break
      }

      return freePlanId
    },
    getVendorId() {
      const hostname = document.location.hostname
      let environment = 'stage'
      switch (hostname) {
        case 'app.pathadvice.ai':
          environment = 'prod'
          break

        case 'app.pathadvice.at':
          environment = 'prod'
          break

        case 'app.letsconnect.at':
          environment = 'prod'
          break
      }
      let vendorId = null

      switch (environment) {
        case 'prod':
          vendorId = 121729
          break

        case 'stage':
          vendorId = 2692
          break

        default:
          vendorId = 2692
          break
      }

      return vendorId
    },
    async fetchSubscriptionPlans(currentPlanId, newPlanId) {
      const getSubscriptionPlans = this.$functions.httpsCallable('getSubscriptionPlans')
      const { data } = await getSubscriptionPlans()
      if (data) {
        const result = JSON.parse(data)
        if (result && result.response) {
          let plans = this.setCurrentPlan(result.response, currentPlanId)
          if (newPlanId) {
            plans = this.setNewPlan(plans, newPlanId)
          }

          return plans
        }
      }
      return null
    },
    setNewPlan(fetchedSubscriptionPlans, subscriptionPlanId) {
      return fetchedSubscriptionPlans.map((plan) => {
        const copyPlan = { ...plan }
        if (copyPlan.id.toString() === subscriptionPlanId) {
          copyPlan.isNewPlan = true
        }

        return copyPlan
      })
    },
    setCurrentPlan(fetchedSubscriptionPlans, subscriptionPlanId) {
      return fetchedSubscriptionPlans.map((plan) => {
        const copyPlan = { ...plan }

        if (copyPlan.id.toString() === subscriptionPlanId) {
          copyPlan.isCurrentPlan = true
        }

        return copyPlan
      })
    },
    getCurrencySymbol(currency) {
      let currencySymbol = '€'
      for (const prop in currency) {
        switch (prop) {
          case 'EUR':
            currencySymbol = '€'
            break

          case 'ARS':
            currencySymbol = 'ARS'
            break

          case 'AUS':
            currencySymbol = 'A$'
            break

          case 'BRL':
            currencySymbol = 'R$'
            break

          case 'CAD':
            currencySymbol = 'CA$'
            break

          case 'CNY':
            currencySymbol = 'CN¥'
            break

          case 'DKK':
            currencySymbol = 'DKK'
            break

          case 'HKD':
            currencySymbol = 'HK$'
            break

          case 'HUF':
            currencySymbol = 'HUF'
            break

          case 'INR':
            currencySymbol = '₹'
            break

          case 'ILS':
            currencySymbol = '₪'
            break

          case 'JPY':
            currencySymbol = '¥'
            break

          case 'MXN':
            currencySymbol = 'MX$'
            break

          case 'TWD':
            currencySymbol = 'NT$'
            break

          case 'NZD':
            currencySymbol = 'NZ$'
            break

          case 'NOK':
            currencySymbol = 'NOK'
            break

          case 'PLN':
            currencySymbol = 'PLN'
            break

          case 'GBP':
            currencySymbol = '£'
            break

          case 'RUB':
            currencySymbol = 'RUB'
            break

          case 'SGD':
            currencySymbol = 'SGD'
            break

          case 'ZAR':
            currencySymbol = 'ZAR'
            break

          case 'KRW':
            currencySymbol = '₩'
            break

          case 'SEK':
            currencySymbol = 'SEK'
            break

          case 'CHF':
            currencySymbol = 'CHF'
            break

          case 'THB':
            currencySymbol = 'THB'
            break

          case 'TRY':
            currencySymbol = 'TRY'
            break

          case 'UAH':
            currencySymbol = 'UAH'
            break

          case 'USD':
            currencySymbol = '$'
            break

          default:
            currencySymbol = '€'
            break
        }

        return currencySymbol
      }
    },

    getTotalUsersForSubscriptionPlan(planId) {
      if (!planId) {
        return 0
      }
      const plans = this.getBaseSubscriptionPlans()
      const plan = plans.find((x) => x.id.toString() === planId.toString())
      return plan && plan.totalUsers ? plan.totalUsers : 0
    }
  }
}
